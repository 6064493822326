import React, {useState} from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Card,
    CardContent,
    Container,
    Grid,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography
} from '@mui/material';
import {useService} from 'src/hook/serviceLocatorHook';
import {useUser} from 'src/hook/authHook';
import {IStaticDataSetService} from 'src/service/staticData';
import {Service} from '../../service/serviceLocator';
import {Link} from 'react-router-dom';
import {IStaticDataDefinitionService} from '../../service/staticData/staticDataDefinitionService';
import {confirmAlert} from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

// TODO: This matches staticDataSetsResponse.ts, we should pull that instead of making our own.
export interface StaticDataManifestsProps {
	context: string;
	definitionId: string;
	editingManifest: any;
	liveManifest: any;
	versions: Array<any>;
	sets: Array<any>;
}

export const StaticDataManifests = (props: StaticDataManifestsProps) => {
    const [user]: any = useUser();
    const [staticDataDefinitionService] = useService<IStaticDataDefinitionService>(Service.StaticDataDefinitionService);
    const [staticDataSetService] = useService<IStaticDataSetService>(Service.StaticDataSetService);
    const [editingManifest, setEditingManifest] = useState<any>(props.editingManifest || {});
    const [liveManifest, setLiveManifest] = useState<any>(props.liveManifest || {});
    const [versions, setVersions] = useState<Array<any>>(props.versions);
    const [comment, setComment] = useState<string>('');
    const [setName, setSetName] = useState<string>('');
    const [error, setError] = useState<string>('');

    const renderManifests = () => {
        const hashesMatch = liveManifest?.hash === editingManifest?.hash;

        let versionExists = false;

        for (const version of props.versions || []) {
            if (version.hash == editingManifest?.hash) {
                versionExists = true;

                break;
            }
        }

        const versionColor = hashesMatch ? '#2E7D32' : !versionExists ? '#BE2596' : '#B8860B';

        const manifestData: any = [];

        const editingDate = new Date(editingManifest.timestamp);

        manifestData.push(<tr key={'state_current'}>
            <TableCell>Current</TableCell>
            <TableCell style={{color: versionColor}}>{editingManifest?.hash ?? '--'}</TableCell>
            <TableCell>{`${editingDate.toDateString()} - ${editingDate.toLocaleTimeString()}`}</TableCell>
            <TableCell>{editingManifest.author}</TableCell>
            <TableCell>{editingManifest.comment}</TableCell>
        </tr>);

        const liveDate = new Date(liveManifest.timestamp);

        manifestData.push(<tr key={'state_live'}>
            <TableCell>Live</TableCell>
            <TableCell style={{color: versionColor}}>{liveManifest?.hash ?? '--'}</TableCell>
            <TableCell>{`${liveDate.toDateString()} - ${liveDate.toLocaleTimeString()}`}</TableCell>
            <TableCell>{liveManifest.publisher || liveManifest.author}</TableCell>
            <TableCell>{liveManifest.publisher_comment || liveManifest.comment}</TableCell>
        </tr>);

        return manifestData;
    };

    const saveVersion = async (event) => {
        await staticDataDefinitionService?.saveDefinition(props.context, props.definitionId, {comment});
        window.location.reload();
    };

    const restoreVersion = async (hash) => {
        await staticDataDefinitionService?.restoreDefinition(props.context, props.definitionId, hash);
        window.location.reload();
    };

    const importVersion = (event) => {
        const reader = new FileReader();

        let fileLoaded = (e) => {
            handleImportVersion(e.target.result);
        };

        fileLoaded = fileLoaded.bind(this);
        reader.onload = fileLoaded;
        reader.readAsText(event.target.files[0]); // read the file
    };

    const handleImportVersion = async (data: any) => {
        await staticDataDefinitionService?.importDefinition(props.context, props.definitionId, data);
        window.location.reload();
    };

    const publishVersion = async (event) => {
        if (!user.name || !comment) {
            setError('You must enter an author and comment before saving/publishing.');
            return;
        }
        const result = await staticDataDefinitionService?.publishDefinition(props.context, props.definitionId, event.target.value, comment);

        if (result.ErrorCode) {
            setError(result.ErrorCode);
            return;
        }
        const manifest = result.Response;
        setLiveManifest(manifest);
    };

    const showConfirmRestoreVersion = (version) => {
        confirmAlert({
            title: 'Restore Version',
            message: `Are you sure you want to restore version ${version}?\nThis will overwrite the local editing data.`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        restoreVersion(version);
                    }
                },
                {
                    label: 'No',
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    onClick: () => {}
                }
            ]
        });
    };

    const renderVersions = () => {
        const versionData: any = [];

        for (const version of versions) {
            let textColor = '#000000';

            if (version.hash === liveManifest.hash) {
                textColor = '#2E7D32';
            } else if (version.hash === editingManifest.hash) {
                textColor = '#B8860B';
            }

            const versionDate = new Date(version.timestamp);

            versionData.push(<tr key={`version_${version.hash}`}>
                <TableCell><span style={{color: textColor}}>{version.hash}</span></TableCell>
                <TableCell>{`${versionDate.toDateString()} - ${versionDate.toLocaleTimeString()}`}</TableCell>
                <TableCell>{version.author}</TableCell>
                <TableCell>{version.comment}</TableCell>
                <TableCell>
                    <Stack direction="row" spacing={2}>
                        <Button onClick={() => {
                            showConfirmRestoreVersion(version.hash);
                        }} value={version.hash} disabled={version.hash === editingManifest.hash} color={'warning'} variant="contained">Restore</Button>
                        <Button onClick={publishVersion} value={version.hash} disabled={version.hash === liveManifest.hash} variant="contained">Publish</Button>
                        <Button onClick={() => {
                            staticDataDefinitionService?.exportDefinition(props.context, props.definitionId, version.hash, `${props.context}-${props.definitionId}-${version.hash}.json`);
                        }} style={{appearance: 'button'}}>Export</Button>
                    </Stack>
                </TableCell>
            </tr>);
        }

        return versionData;
    };

    const showConfirmDeleteSet = (setId) => {
        confirmAlert({
            title: 'Delete Set',
            message: `Are you sure you want to delete set ${setId}?`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        await staticDataSetService?.deleteSet(props.context, props.definitionId, setId);
                        window.location.reload();
                    }
                },
                {
                    label: 'No',
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    onClick: () => {}
                }
            ]
        });
    };

    const renderLastChanges = () => {
        const lastEditedDate = new Date(editingManifest.timestamp);

        return (<div key={'lastChanges'}>
            <span style={{'fontWeight': 'bold'}}>Author: {editingManifest.author || '--'}</span><br/>
            <span>Date: {lastEditedDate.toDateString()} - {lastEditedDate.toLocaleTimeString()}</span><br/>
            <span style={{'fontStyle': 'italic'}}>Comment: {editingManifest.comment || '--'}</span><br/>
            <span style={{'color': '#FF0000'}}>{editingManifest.last_validation ? `Error: ${editingManifest.last_validation}` : ''}</span>
        </div>);
    };

    const renderSets = () => {
        const setData: any = [];

        for (const set of props.sets) {
            const textColor = '#000000';
            const lastEditorDate = new Date(set.lastChange?.timestamp);

            const conditions: any[] = [];

            for (const condition of (set.conditions || [])) {
                conditions.push(<div key={`set_${set.id}_conditions`}>
                    {condition.type}:{condition.operator}:{condition.key}:{condition.value}<br/>
                </div>);
            }

            setData.push(<tr style={{color: textColor}} key={`set_${set.id}`}>
                <TableCell>{set.id}</TableCell>
                <TableCell>{set.name}</TableCell>
                <TableCell>
                    <span>Author: {set.lastChange?.author}</span><br/>
                    <span>Comment: {set.lastChange?.comment}</span><br/>
                    <span>Date: {`${lastEditorDate.toDateString()} - ${lastEditorDate.toLocaleTimeString()}`}</span><br/>
                    <span style={{color: '#FF0000'}}>{set.lastChange?.validationResult}</span>
                </TableCell>
                <TableCell>{set.priority}</TableCell>
                <TableCell>{conditions}</TableCell>
                <TableCell>
                    <Button variant={'contained'}><Link to={`/admin/staticData/${props.context}/${props.definitionId}/${set.id}`}
                        style={{'color': '#FFFFFF', 'textDecoration': 'none'}}>Edit</Link></Button>
                    <br/><br/>
                    <Button variant={'contained'} color={'error'} onClick={() => {
                        showConfirmDeleteSet(set.id);
                    }}>Delete</Button>
                </TableCell>
            </tr>);
        }

        return setData;
    };

    return (
        <Container maxWidth="xl">
            <Stack spacing={2}>
                <Card variant="outlined">
                    <CardContent>
                        <Accordion defaultExpanded={true}>
                            <AccordionSummary><Typography variant="h5">Current State</Typography></AccordionSummary>
                            <AccordionDetails>
                                <Stack spacing={2}>
                                    <span style={{fontStyle: 'italic', color: '#2E7D32'}}>Editing Data Matches Published</span>
                                    <span style={{fontStyle: 'italic', color: '#B8860B'}}>Editing Data Out of Sync with Published</span>
                                    <span style={{fontStyle: 'italic', color: '#BE2596'}}>Unsaved Version Data</span>

                                    <TableContainer component={Paper}>
                                        <Table sx={{minWidth: 700}} aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell sx={{fontWeight: 'bold', backgroundColor: '#EEEEEE'}}>State</TableCell>
                                                    <TableCell sx={{fontWeight: 'bold', backgroundColor: '#EEEEEE'}}>Hash</TableCell>
                                                    <TableCell sx={{fontWeight: 'bold', backgroundColor: '#EEEEEE'}}>Timestamp</TableCell>
                                                    <TableCell sx={{fontWeight: 'bold', backgroundColor: '#EEEEEE'}}>Publisher/Author</TableCell>
                                                    <TableCell sx={{fontWeight: 'bold', backgroundColor: '#EEEEEE'}}>Comment</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>{renderManifests()}</TableBody>
                                        </Table>
                                    </TableContainer>
                                    <h3>Last Changes</h3>
                                    {renderLastChanges()}
                                </Stack>
                            </AccordionDetails>
                        </Accordion>
                    </CardContent>
                </Card>
                <Card variant="outlined">
                    <CardContent>
                        <Accordion defaultExpanded={true}>
                            <AccordionSummary><Typography variant="h5">Publishing</Typography></AccordionSummary>
                            <AccordionDetails>
                                <Stack spacing={2}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={2}>
                                            <Typography variant="h6">Save/Publish Comment: </Typography>
                                        </Grid>
                                        <Grid item xs={20}>
                                            <TextField rows={4} defaultValue={comment} onChange={evt => setComment(evt.target.value)} multiline/>
                                        </Grid>
                                        <Grid item xs={20}>
                                            <span style={{color: '#FF0000'}}>{error}</span>
                                        </Grid>
                                    </Grid>

                                    <Button onClick={saveVersion} variant="contained">Save</Button>
                                </Stack>
                            </AccordionDetails>
                        </Accordion>
                    </CardContent>
                </Card>
                <Card>
                    <CardContent>
                        <Accordion defaultExpanded={true}>
                            <AccordionSummary><Typography variant="h5">Versions</Typography></AccordionSummary>
                            <AccordionDetails>
								Import Version <input type="file" multiple={false} accept=".json,application/json" onChange={importVersion}/>

                                <TableContainer component={Paper}>
                                    <Table sx={{minWidth: 700}} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{fontWeight: 'bold', backgroundColor: '#EEEEEE'}}>Version</TableCell>
                                                <TableCell sx={{fontWeight: 'bold', backgroundColor: '#EEEEEE'}}>Timestamp</TableCell>
                                                <TableCell sx={{fontWeight: 'bold', backgroundColor: '#EEEEEE'}}>Author</TableCell>
                                                <TableCell sx={{fontWeight: 'bold', backgroundColor: '#EEEEEE'}}>Comment</TableCell>
                                                <TableCell sx={{fontWeight: 'bold', backgroundColor: '#EEEEEE'}}>Action</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>{renderVersions()}</TableBody>
                                    </Table>
                                </TableContainer>
                            </AccordionDetails>
                        </Accordion>
                    </CardContent>
                </Card>
                <Card>
                    <CardContent>
                        <Accordion defaultExpanded={true}>
                            <AccordionSummary><Typography variant="h5">Sets</Typography></AccordionSummary>
                            <AccordionDetails>
                                <input type="text" name="newSet" defaultValue={setName} onChange={evt => setSetName(evt.target.value)}/>&nbsp;&nbsp;
                                <Button onClick={async () => {
                                    await staticDataSetService?.createSet(props.context, props.definitionId, {
                                        name: setName
                                    });

                                    window.location.reload();
                                }} variant={'contained'}>Create New Set</Button>
                                <TableContainer component={Paper}>
                                    <Table sx={{minWidth: 700}} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{fontWeight: 'bold', backgroundColor: '#EEEEEE'}}>Id</TableCell>
                                                <TableCell sx={{fontWeight: 'bold', backgroundColor: '#EEEEEE'}}>Name</TableCell>
                                                <TableCell sx={{fontWeight: 'bold', backgroundColor: '#EEEEEE'}}>State</TableCell>
                                                <TableCell sx={{fontWeight: 'bold', backgroundColor: '#EEEEEE'}}>Priority</TableCell>
                                                <TableCell sx={{fontWeight: 'bold', backgroundColor: '#EEEEEE'}}>Conditions</TableCell>
                                                <TableCell sx={{fontWeight: 'bold', backgroundColor: '#EEEEEE'}}>Actions</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>{renderSets()}</TableBody>
                                    </Table>
                                </TableContainer>
                            </AccordionDetails>
                        </Accordion>
                    </CardContent>
                </Card>
            </Stack>
        </Container>
    );
};
