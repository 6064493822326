import {createTheme} from '@mui/material/styles';
import {red} from '@mui/material/colors';

export const localTheme = createTheme({
    palette: {
        secondary: {
            main: '#8A9A5B',
        },
        error: {
            main: red.A400,
        },
    },
    components: {
        MuiCard: {
            styleOverrides: {
                root: {
                    backgroundColor: '#dddddd',
                    border: '1px solid #e0e0e0',
                    boxShadow: '0px 2px 4px rgba(0,0,0,0.05)',
                    borderRadius: '8px',
                },
            },
        },
    },
});

export const devTheme = createTheme({
    palette: {
        secondary: {
            main: '#F9A825',
        },
        error: {
            main: '#D32F2F',
        },
    },
    components: {
        MuiCard: {
            styleOverrides: {
                root: {
                    backgroundColor: '#dddddd',
                    border: '1px solid #e0e0e0',
                    boxShadow: '0px 2px 4px rgba(0,0,0,0.05)',
                    borderRadius: '8px',
                },
            },
        },
    },
});

export const playtestTheme = createTheme({
    palette: {
        secondary: {
            main: '#4CE8DA',
        },
        error: {
            main: '#D32F2F',
        },
    },
    components: {
        MuiCard: {
            styleOverrides: {
                root: {
                    backgroundColor: '#dddddd',
                    border: '1px solid #e0e0e0',
                    boxShadow: '0px 2px 4px rgba(0,0,0,0.05)',
                    borderRadius: '8px',
                },
            },
        },
    },
});

export const internalTheme = createTheme({
    palette: {
        secondary: {
            main: '#C9A9EB',
        },
        error: {
            main: '#D32F2F',
        },
    },
    components: {
        MuiCard: {
            styleOverrides: {
                root: {
                    backgroundColor: '#dddddd',
                    border: '1px solid #e0e0e0',
                    boxShadow: '0px 2px 4px rgba(0,0,0,0.05)',
                    borderRadius: '8px',
                },
            },
        },
    },
});

export const stageTheme = createTheme({
    palette: {
        secondary: {
            main: '#4B5CA7',
        },
        error: {
            main: '#D32F2F',
        },
    },
    components: {
        MuiCard: {
            styleOverrides: {
                root: {
                    backgroundColor: '#dddddd',
                    border: '1px solid #e0e0e0',
                    boxShadow: '0px 2px 4px rgba(0,0,0,0.05)',
                    borderRadius: '8px',
                },
            },
        },
    },
});

export const prodTheme = createTheme({
    palette: {
        secondary: {
            main: '#00A300',
        },
        error: {
            main: '#D32F2F',
        },
    },
    components: {
        MuiCard: {
            styleOverrides: {
                root: {
                    backgroundColor: '#dddddd',
                    border: '1px solid #e0e0e0',
                    boxShadow: '0px 2px 4px rgba(0,0,0,0.05)',
                    borderRadius: '8px',
                },
            },
        },
    },
});

