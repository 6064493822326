import React, {useState} from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Card,
    CardContent, CircularProgress,
    Container, FormControl,
    FormGroup,
    Grid,
    MenuItem,
    Paper,
    Select,
    Stack,
    Table,
    TableBody,
    TableCell, TableContainer,
    TableHead,
    TableRow, TextField,
    Typography
} from '@mui/material';
import StaticDataSchemaMap from 'src/temp/staticDataSchemaMap';
import {IPlayerService} from 'src/service/player/playerService';
import {useService} from 'src/hook/serviceLocatorHook';
import {useUser} from 'src/hook/authHook';
import {Service} from '../../service/serviceLocator';
import {confirmAlert} from 'react-confirm-alert';
import {Link} from 'react-router-dom';
import {InboxDialog, InboxItem, InboxItemVisibility} from './inbox/inboxDialog';
import {NarrativeChapterEditor} from './narrative/narrativeChapter';
import {InboxService} from 'src/service/inbox/inboxService';
import {SaveStateService} from '../../service/player/saveStateService';
import {SaveState} from './saveState/saveStateDialog';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

export interface PlayerProps {
    playerId: string;
    env: string;
    player: any;
    inboxes: any;
    gcpProjectId: string;
    contexts: Array<string>;
    schema: any;
    telemetryEventIds: Array<string>;
}

export const PlayerProfile = (props: PlayerProps) => {
    const {player} = props;
    const [user]: any = useUser();
    const [openInboxForm, setOpenInboxForm] = useState<boolean>(false);
    const [playerService] = useService<IPlayerService>(Service.PlayerService);
    const [inboxService] = useService<InboxService>(Service.InboxService);

    const [saveStateService] = useService<SaveStateService>(Service.SaveStateService);
    const [saveStateName, setSaveStateName] = useState<string>('');
    const [saveStateDescription, setSaveStateDescription] = useState<string>('');
    const [saveStates, setSaveStates] = useState<Array<SaveState>>([]);
    const [selectedSaveStateId, setSelectedSaveStateId] = useState<string>('');
    const [createSaveStateSuccess, setCreateSaveStateSuccess] = useState<boolean>(false);

    const [schema, setSchema] = useState<any>(StaticDataSchemaMap.deserialize(props.schema));
    const [grantRevokeError, setGrantRevokeError] = useState<string>('');

    const asyncHandleSubmitGrantRevoke = async (inboxItem: InboxItem) => {
        inboxItem.author = `${user.name} (${user.email})`;
        const result = await inboxService?.sendInboxToPlayer(props.playerId, inboxItem);
        console.log(result.ErrorCode, 'result.ErrorCode');
        setGrantRevokeError(result.ErrorCode ?? 'Success');
    };

    const [accountResetType, setAccountResetType] = useState<string>('Account');
    const [notificationContext, setNotificationContext] = useState<any>({
        title: '',
        body: '',
        error: ''
    });
    const [newSegmentKey, setNewSegmentKey] = useState<string>('');
    const [newSegmentValue, setNewSegmentValue] = useState<string>('');

    const [staticDataSimPlatform, setStaticDataSimPlatform] = useState<string>(player?.metadata?.platform ?? '');
    const [staticDataSimClientVersion, setStaticDataSimClientVersion] = useState<string>(player?.metadata?.clientVersion ?? '');
    const [staticDataSimLanguage, setStaticDataSimLanguage] = useState<string>(player?.metadata?.language ?? '');
    const [staticDataSimIpAddress, setStaticDataSimIpAddress] = useState<string>('');
    const [staticDataSim, setStaticDataSim] = useState<any>();

    const [telemetryEventId, setTelemetryEventId] = useState<string>('ALL');
    const [telemetryStartAt, setTelemetryStartAt] = useState<string>(new Date().toLocaleDateString('zh-Hans-CN').replaceAll('/', '-'));
    const [telemetryEndAt, setTelemetryEndAt] = useState<string>(new Date().toLocaleDateString('zh-Hans-CN').replaceAll('/', '-'));
    const [telemetryEvents, setTelemetryEvents] = useState<Array<any>>([]);
    const [findingTelemetry, setFindingTelemetry] = useState<boolean>();

    const rewardTypes: string[] = schema.getSchema('RewardType').properties.map((t) => t);
    const showConfirmReset = () => {
        confirmAlert({
            title: 'Player Account Maintenance',
            message: 'Are you sure you want to reset this player?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        resetPlayerAccount();
                    }
                },
                {
                    label: 'No',
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    onClick: () => {
                    }
                }
            ]
        });
    };

    const resetPlayerAccount = async () => {
        await playerService?.resetPlayer(props.playerId, accountResetType);
        window.location.reload();
    };

    const getTelemetryEvents = async () => {
        setFindingTelemetry(true);

        const telemetryEvents = await playerService?.getTelemetryEvents(props.playerId, telemetryEventId, telemetryStartAt, telemetryEndAt);

        setFindingTelemetry(false);
        setTelemetryEvents(telemetryEvents.Response || []);
    };

    const renderAccountActions = () => {
        return <div key={'accountActions'}>
            <h3>Account Reset</h3>
            <Select defaultValue={'Account'} onChange={evt => setAccountResetType(evt.target.value)}>
                <MenuItem key={'Account'} value={'Account'}>Account (Soft)</MenuItem>
                <MenuItem key={'AccountHard'} value={'AccountHard'}>Account (Hard)</MenuItem>
                <MenuItem key={'PlayerPrefs'} value={'PlayerPrefs'}>PlayerPrefs</MenuItem>
                <MenuItem key={'StaticData'} value={'StaticData'}>StaticData</MenuItem>
                {/*<MenuItem key={'Addressables'} value={'Addressables'}>Addressables</MenuItem>*/}
            </Select><br/>
            <Button variant="contained" onClick={showConfirmReset}>Reset Player Account</Button>
        </div>;
    };

    const renderTelemetryLog = () => {
        const headerNames: Array<string> = [];
        const headers: Array<any> = [];
        const rows: Array<any> = [];

        if (telemetryEvents.length == 0) {
            headers.push(<TableCell>No Events Found.</TableCell>);
        } else {
            let biggestRow = {};
            let highKeyCount = 0;

            for (const telemetryEvent of telemetryEvents) {
                const keys = Object.keys(telemetryEvent);

                if (keys.length > highKeyCount) {
                    biggestRow = telemetryEvent;
                    highKeyCount = keys.length;
                }
            }

            for (const key in biggestRow) {
                headerNames.push(key);
                headers.push(<TableCell>{key}</TableCell>);
            }

            for (const telemetryEvent of telemetryEvents) {
                rows.push(<TableRow>
                    {headerNames.map(k => {
                        if (k == 'event_params') {
                            return <TableCell>
                                {telemetryEvent[k].split('^').map(i => {
                                    return <div>{i}</div>;
                                })}
                            </TableCell>;
                        }

                        return <TableCell>{telemetryEvent[k]}</TableCell>;
                    })}
                </TableRow>);
            }
        }

        const loading: Array<any> = [];
        if (findingTelemetry) {
            loading.push(<div key={'finding_telemetry'}>
                <CircularProgress /> Loading Telemetry...
            </div>);
        }

        return <div key={'telemetryLog'}>
            <h3>Telemetry Log</h3>
            <Select defaultValue={props.telemetryEventIds[0]} onChange={evt => setTelemetryEventId(evt.target.value)}>
                {props.telemetryEventIds.map(ti => {
                    return <MenuItem key={ti} value={ti}>{ti}</MenuItem>;
                })}
            </Select><br/>
            {/*KK - hahahhahahahahhahahahhahaha*/}
            <DatePicker selected={Date.parse(telemetryStartAt)} onChange={(date) => setTelemetryStartAt(date.toLocaleDateString('zh-Hans-CN').replaceAll('/', '-'))} />
            to
            <DatePicker selected={Date.parse(telemetryEndAt)} onChange={(date) => setTelemetryEndAt(date.toLocaleDateString('zh-Hans-CN').replaceAll('/', '-'))} />
            <br />
            <Button variant="contained" onClick={getTelemetryEvents}>Get Events</Button>
            <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
            {loading}
            <br/>
            *All dates and times are in UTC (24h).
            <TableContainer component={Paper}>
                <Table sx={{minWidth: 700}} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            {headers}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>;
    };

    const renderPlayerTable = (tableName: string, headerNames: Array<string>, rows: Array<any>) => {
        const headers = [<TableCell key={`${tableName}_context`} sx={{fontWeight: 'bold', backgroundColor: '#EEEEEE'}}>Context</TableCell>];

        for (const headerName of headerNames) {
            headers.push(<TableCell key={`${tableName}_${headerName}`} sx={{fontWeight: 'bold', backgroundColor: '#EEEEEE'}}>{headerName}</TableCell>);
        }

        return (<div key={tableName}><h3>{tableName}</h3>
            <TableContainer component={Paper}>
                <Table sx={{minWidth: 700}} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            {headers}
                        </TableRow>
                    </TableHead>
                    <TableBody>{rows}</TableBody>
                </Table>
            </TableContainer>
        </div>);
    };

    const renderInbox = () => {
        const inboxItems: any = [];

        const itemsReversed: any = [];
        for (const itemId in props.inboxes) {
            itemsReversed.push(props.inboxes[itemId]);
        }
        itemsReversed.reverse();

        for (const inboxItem of itemsReversed) {
            inboxItems.push(<TableRow>
                <TableCell>None</TableCell>
                <TableCell>{new Date(inboxItem.CreatedAt).toString()}</TableCell>
                <TableCell>{schema.getSchema('InboxItemCategory').properties[inboxItem.Category]}</TableCell>
                <TableCell>{schema.getSchema('InboxItemVisibility').properties[inboxItem.Visibility]}</TableCell>
                <TableCell>{inboxItem.Title}</TableCell>
                <TableCell>{inboxItem.Message}</TableCell>
                <TableCell>{inboxItem.Reward}</TableCell>
                <TableCell>{inboxItem.State ?? 'Unseen'}</TableCell>
                <TableCell>{schema.getSchema('InboxActionType').properties[inboxItem.ActionType]}</TableCell>
                <TableCell>{inboxItem.Action}</TableCell>
                <TableCell>{inboxItem.author}</TableCell>
                <TableCell>{inboxItem.comment}</TableCell>
            </TableRow>);
        }

        return <div key={'inbox'}>
            <Button variant="contained" onClick={() => {
                setOpenInboxForm(true);
            }}>Create Inbox Item</Button>
            {renderPlayerTable('Inbox', ['CreatedAt', 'Category', 'Visibility', 'Title', 'Message', 'Reward', 'State', 'ActionType', 'Action', 'Author', 'Comment'], inboxItems)}
        </div>;
    };

    const renderImportExport = () => {
        const saveStatesRendered: Array<any> = [];

        if (saveStates.length > 0) {
            const saveStateItems: Array<any> = [];

            for (const saveState of saveStates) {
                saveStateItems.push(<MenuItem key={saveState.id} value={saveState.id}>{saveState.name}</MenuItem>);
            }

            saveStatesRendered.push(<FormControl sx={{m: 1, minWidth: 120}}><Select defaultValue={1} id="selectedSaveStateId"
                onChange={(evt) => setSelectedSaveStateId(evt.target.value.toString())}>{saveStateItems}</Select></FormControl>);
            saveStatesRendered.push(<Button variant="contained" onClick={async () => {
                await saveStateService?.replacePlayerWithSaveState(props.playerId, selectedSaveStateId);
                window.location.reload();
            }}>Replace</Button>);
        }

        return <div key={'importExport'}>
            <h3>Import/Export</h3>
            <Button variant="contained" onClick={() => {
                playerService?.exportPlayer(props.playerId, `${props.playerId}-export-${props.env}.json`);
            }}>Export</Button>
            <br/><br/>
            Import Player <input type="file" multiple={false} accept=".json,application/json" onChange={importVersion} />

            <br/>

            <h3>Save State Export</h3>
            <FormControl sx={{m: 1, minWidth: 120}}><TextField defaultValue={saveStateName} onChange={(evt) => setSaveStateName(evt.target.value)} label={'Name'}/></FormControl>
            <FormControl sx={{m: 1, minWidth: 120}}><TextField defaultValue={saveStateDescription} onChange={(evt) => setSaveStateDescription(evt.target.value)} label={'Description'}/></FormControl>
            <br/>
            {createSaveStateSuccess ? <span color={'#2E7D32'}>Created successfully.</span> : ''}
            <br/><br/>
            <Button variant="contained" onClick={async () => {
                await saveStateService?.createSaveStateFromPlayerId(props.playerId, saveStateName, saveStateDescription);
                setCreateSaveStateSuccess(true);
            }}>Create Save State</Button>

            <h3>Replace Player with Save State</h3>

            <Button variant="contained" onClick={async () => {
                const saveStates = await saveStateService?.getSaveStates();

                setSaveStates(saveStates.Response);
            }}>Refresh Save States</Button>
            <br/><br/>
            {saveStatesRendered}
        </div>;
    };

    const renderMyRealForest = () => {

        return <div key={'myRealForest'}>
            <p>Remove all My Real Forest tree layout data. <b>You must delete the tree inventory from the Impact Platform first!</b></p>
            <Button variant="contained" onClick={() => {
                confirmAlert({
                    title: 'Clear My Real Forest Data',
                    message: 'Are you sure you want to delete all tree data?\n(ensure you have removed tree inventory from the Impact Platform first)',
                    buttons: [
                        {
                            label: 'Yes',
                            onClick: async () => {
                                await playerService?.deleteMyRealForestData(props.playerId);
                                window.location.reload();
                            }
                        },
                        {
                            label: 'No',
                            // eslint-disable-next-line @typescript-eslint/no-empty-function
                            onClick: () => {}
                        }
                    ]
                });
            }}>Clear All Data</Button>
        </div>;
    };

    const renderStaticData = () => {
        const staticDataSimTableContents: any[] = [];

        if (staticDataSim) {
            for (const contextId in staticDataSim.contexts) {
                const context = staticDataSim.contexts[contextId];
                staticDataSimTableContents.push(<TableRow>
                    <TableCell>{contextId}</TableCell>
                    <TableCell colSpan={4}>
                        <Typography>Id: {context.id}</Typography>
                        <Typography>Description: {context.description}</Typography>
                    </TableCell>
                </TableRow>);

                for (const definitionId in context.manifests) {
                    const manifest = context.manifests[definitionId];

                    let version;
                    let set;

                    if (manifest?.url) {
                        const splitBySlash = manifest.url.split('/');
                        const splitByUnderscore = splitBySlash[splitBySlash.length - 1].split('_');
                        version = splitByUnderscore[0];

                        if (splitByUnderscore[1]) {
                            set = splitByUnderscore[1].split('.')[0];
                        } else {
                            set = 'NoSetMatch';
                        }
                    }

                    staticDataSimTableContents.push(<TableRow>
                        <TableCell>{contextId}</TableCell>
                        <TableCell><Link to={`/admin/staticData/${contextId}/${definitionId}`}>{definitionId}</Link></TableCell>
                        <TableCell>{version}</TableCell>
                        <TableCell><Link to={`/admin/staticData/${contextId}/${definitionId}/${set}`}>{set}</Link></TableCell>
                        <TableCell><a href={manifest.url}>CDN</a></TableCell>
                    </TableRow>);
                }
            }
        }

        return <div key={'staticData'}>
            Platform: <TextField defaultValue={staticDataSimPlatform} onChange={(evt) => setStaticDataSimPlatform(evt.target.value)}/><br/>
            Client Version: <TextField defaultValue={staticDataSimClientVersion} onChange={(evt) => setStaticDataSimClientVersion(evt.target.value)}/><br/><br/>
            Language: <TextField defaultValue={staticDataSimLanguage} onChange={(evt) => setStaticDataSimLanguage(evt.target.value)}/><br/><br/>
            IP Address: <TextField defaultValue={staticDataSimIpAddress} onChange={(evt) => setStaticDataSimIpAddress(evt.target.value)}/><br/><br/>

            <Button variant="contained" onClick={async () => {
                const data = await playerService?.simulateLogin(props.playerId, staticDataSimIpAddress, staticDataSimPlatform, staticDataSimClientVersion, staticDataSimLanguage);
                setStaticDataSim(data.Response);
            }}>Simulate Login</Button>

            <TableContainer component={Paper}>
                <Table sx={{minWidth: 700}} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{fontWeight: 'bold', backgroundColor: '#EEEEEE'}}>Context</TableCell>
                            <TableCell sx={{fontWeight: 'bold', backgroundColor: '#EEEEEE'}}>Definition</TableCell>
                            <TableCell sx={{fontWeight: 'bold', backgroundColor: '#EEEEEE'}}>Version Hash</TableCell>
                            <TableCell sx={{fontWeight: 'bold', backgroundColor: '#EEEEEE'}}>Set Hash</TableCell>
                            <TableCell sx={{fontWeight: 'bold', backgroundColor: '#EEEEEE'}}>URL</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {staticDataSimTableContents}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>;
    };

    const importVersion = (event) => {
        const reader = new FileReader();

        let fileLoaded = (e) => {
            handleImportVersion(e.target.result);
        };

        fileLoaded = fileLoaded.bind(this);
        reader.onload = fileLoaded;
        reader.readAsText(event.target.files[0]); // read the file
    };

    const handleImportVersion = async (data: any) => {
        const result = await playerService?.importPlayer(props.playerId, data);
        window.location.reload();
    };

    const handleSubmitSendNotification = (event) => {
        asyncHandleSubmitSendNotification();
        event.preventDefault();
    };

    const asyncHandleSubmitSendNotification = async () => {

        const result = await playerService?.sendNotification(props.playerId, {
            ...notificationContext,
            fcmToken: props.player.data?.Shared?.pushNotifications.FCMToken
        });
        const newContext = {
            ...notificationContext,
            error: result.ErrorCode ?? result.Response
        };
        setNotificationContext(newContext);
    };

    const changeSendNotificationTitle = (event) => {
        const newNotificationContext = {
            ...notificationContext,
            title: event.target.value
        };
        setNotificationContext(newNotificationContext);
    };

    const changeSendNotificationBody = (event) => {
        const newNotificationContext = {
            ...notificationContext,
            body: event.target.value
        };
        setNotificationContext(newNotificationContext);
    };


    const renderNotifications = () => {
        const additionalTopics: any = [];

        for (const topic in props.player?.data?.Shared?.pushNotifications?.Topics) {
            additionalTopics.push(<TableRow key={topic}><TableCell>{topic}</TableCell></TableRow>);
        }

        return <div key={'notifications'}>
            <h3>Token</h3>
            <input type={'text'} readOnly={true} value={props.player?.data?.Shared?.pushNotifications?.FCMToken}/>

            <h3>Topics</h3>
            <TableContainer component={Paper}>
                <Table sx={{minWidth: 700}} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{fontWeight: 'bold', backgroundColor: '#EEEEEE'}}>Topic</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow><TableCell>allUsers</TableCell></TableRow>
                        {additionalTopics}
                    </TableBody>
                </Table>
            </TableContainer>

            <h3>Send Notification</h3>
            <form onSubmit={handleSubmitSendNotification}>
                <FormGroup>
                    <Grid container spacing={2}>
                        <label>Title: <input type={'text'} defaultValue={notificationContext.title} onChange={changeSendNotificationTitle}/></label>
                        <label>Body: <input type={'text'} defaultValue={notificationContext.body} onChange={changeSendNotificationBody}/></label>
                    </Grid>
                    <br/>
                    <Button variant="contained" type="submit">Send</Button>
                    <span style={{color: notificationContext.error != 'Success' ? '#FF0000' : '#2E7D32'}}>{notificationContext.error}</span>
                </FormGroup>
            </form>
        </div>;
    };

    const showConfirmDeleteSegment = (segmentKey) => {
        confirmAlert({
            title: 'Delete Segment',
            message: `Are you sure you want to delete segment ${segmentKey}?`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        await playerService?.removeSegment(props.playerId, segmentKey);
                        window.location.reload();
                    }
                },
                {
                    label: 'No',
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    onClick: () => {
                    }
                }
            ]
        });
    };

    const renderSegments = () => {
        const segments: any[] = [];

        for (const segment in props.player.metadata.segments) {
            segments.push(<TableRow>
                <TableCell>{segment}</TableCell>
                <TableCell>{props.player.metadata.segments[segment]}</TableCell>
                <TableCell>
                    <Button variant={'contained'} color={'error'} onClick={() => {
                        showConfirmDeleteSegment(segment);
                    }}>Delete</Button>
                </TableCell>
            </TableRow>);
        }

        return segments;
    };

    const addSegment = async () => {
        await playerService?.addSegment(props.playerId, newSegmentKey, newSegmentValue);
        window.location.reload();
    };

    const renderPlayer = () => {
        if (!props.player) {
            return <div key={'notFound'}></div>;
        }

        const resources: any = [];

        for (const resource of props.player.data?.[StaticDataSchemaMap.SharedContext]?.resources || []) {
            resources.push(<TableRow key={resource.Id}>
                <TableCell>{StaticDataSchemaMap.SharedContext}</TableCell>
                <TableCell>{resource.Id}</TableCell>
                <TableCell>{resource.balance}</TableCell>
            </TableRow>);
        }
        const narratives: any = [];
        const progressions: any = [];
        const permissions: any = [];
        const tasks: any = [];
        const animals: any = [];
        const merge: any = [];
        const plantables: any = [];
        const vip: any = [];

        const vipExpiresAt = props.player.data?.[StaticDataSchemaMap.SharedContext]?.vip?.expiresAt;
        vip.push(<TableRow key={'vip'}>
            <TableCell>Shared</TableCell>
            <TableCell>{vipExpiresAt ? new Date(vipExpiresAt * 1000).toLocaleString() : 'Never Activated'}</TableCell>
            <TableCell>{props.player.data?.[StaticDataSchemaMap.SharedContext]?.vip?.count}</TableCell>
        </TableRow>);

        for (const context of props.contexts) {
            if (context == StaticDataSchemaMap.SharedContext) {
                continue;
            }

            progressions.push(<TableRow>
                <TableCell>{context}</TableCell>
                <TableCell>{props.player.data?.[context]?.contextualPlayer?.level}</TableCell>
                <TableCell>{props.player.data?.[context]?.contextualPlayer?.xp}</TableCell>
                <TableCell>{props.player.data?.[context]?.contextualPlayer?.DayNightCycle || 'Day'}</TableCell>
            </TableRow>);

            for (const permission of props.player.data?.[context]?.Permissions?.permissions || []) {
                permissions.push(<TableRow key={permission}>
                    <TableCell>{context}</TableCell>
                    <TableCell>{permission}</TableCell>
                    <TableCell>{'Yes'}</TableCell>
                </TableRow>);
            }
            narratives.push(<NarrativeChapterEditor chapters={props.player.data?.[context]?.narrative || []} playerId={props.playerId} context={context} key={`${context}_narrative`}/>);
            for (const task of props.player.data?.[context]?.tasks || []) {
                tasks.push(<TableRow key={task.id}>
                    <TableCell>{context}</TableCell>
                    <TableCell>{task.id}</TableCell>
                    <TableCell>{task.cti}</TableCell>
                </TableRow>);
            }

            for (const animal of props.player.data?.[context]?.animals || []) {
                animals.push(<TableRow key={animal.DefinitionId}>
                    <TableCell>{context}</TableCell>
                    <TableCell>{animal.DefinitionId}</TableCell>
                    <TableCell>{animal.level}</TableCell>
                    <TableCell>{animal.status}</TableCell>
                </TableRow>);
            }

            for (const plantable of props.player.data?.[context]?.plantables || []) {
                plantables.push(<TableRow key={`${plantable.Guid}`}>
                    <TableCell>{context}</TableCell>
                    <TableCell>{plantable.DefinitionId}</TableCell>
                    <TableCell>{plantable.level}</TableCell>
                    <TableCell>{plantable.CumulativePayoutTotal}</TableCell>
                </TableRow>);
            }

            let gridIndex = 0;
            for (const grid of props.player.data?.[context]?.merge?.MergeGridModels || []) {
                const gridObjects: any = [];
                for (const gridObject of grid.MergeObjects || []) {
                    gridObjects.push(<div key={`gridIndex_${context}_${gridObject.Index}_${gridIndex}`}>[{gridObject.DefinitionId} - {gridObject.Index}]</div>);
                }

                merge.push(<TableRow key={gridIndex}>
                    <TableCell>{context}</TableCell>
                    <TableCell>{gridIndex}</TableCell>
                    <TableCell>{grid.UnlockedRows?.join(',')}</TableCell>
                    <TableCell>{gridObjects}</TableCell>
                </TableRow>);

                gridIndex++;
            }
        }

        return <div key={props.playerId}>
            <Container maxWidth="xl"><h2>{props.playerId}</h2></Container>
            <Container maxWidth="xl">
                <Stack spacing={2}>
                    <Card variant="outlined">
                        <CardContent>
                            <Accordion defaultExpanded={false}>
                                <AccordionSummary><Typography variant="h5">Account Actions</Typography></AccordionSummary>
                                <AccordionDetails>
                                    <a href={`https://console.firebase.google.com/u/0/project/${props.gcpProjectId}/firestore/data/~2Fplayers~2F${props.playerId}`}>View In Firebase</a>
                                    {renderAccountActions()}
                                </AccordionDetails>
                            </Accordion>
                        </CardContent>
                    </Card>
                </Stack>
            </Container>
            <Container maxWidth="xl">
                <Stack spacing={2}>
                    <Card variant="outlined">
                        <CardContent>
                            <Accordion defaultExpanded={true}>
                                <AccordionSummary><Typography variant="h5">Save State</Typography></AccordionSummary>
                                <AccordionDetails>
                                    {renderImportExport()}
                                </AccordionDetails>
                            </Accordion>
                        </CardContent>
                    </Card>
                </Stack>
            </Container>
            <Container maxWidth="xl">
                <Stack spacing={2}>
                    <Card variant="outlined">
                        <CardContent>
                            <Accordion defaultExpanded={true}>
                                <AccordionSummary><Typography variant="h5">Info</Typography></AccordionSummary>
                                <AccordionDetails>
                                    <TableContainer component={Paper}>
                                        <Table sx={{minWidth: 700}} aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell sx={{fontWeight: 'bold', backgroundColor: '#EEEEEE'}}>Type</TableCell>
                                                    <TableCell sx={{fontWeight: 'bold', backgroundColor: '#EEEEEE'}}>Value</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell>Name</TableCell>
                                                    <TableCell>{props.player.data?.[StaticDataSchemaMap.SharedContext]?.sharedPlayer?.name}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Group</TableCell>
                                                    <TableCell>{props.player.data?.Shared?.group?.id ?? 'No Group'}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Created At</TableCell>
                                                    <TableCell>{new Date((props.player.metadata.createdAt || 0) * 1000).toString()}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Last Logged In</TableCell>
                                                    <TableCell>{new Date((props.player.metadata.lastLoggedIn || 0) * 1000).toString()}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Last Saved At</TableCell>
                                                    <TableCell>{new Date((props.player.metadata.lastSavedAt || 0) * 1000).toString()}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Language</TableCell>
                                                    <TableCell>{props.player.metadata.language}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Country</TableCell>
                                                    <TableCell>{props.player.metadata.country}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Platform</TableCell>
                                                    <TableCell>{props.player.metadata.platform}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>Current Version</TableCell>
                                                    <TableCell>{props.player.metadata.clientVersion}</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </AccordionDetails>
                            </Accordion>
                        </CardContent>
                    </Card>
                </Stack>
            </Container>
            <Container maxWidth="xl">
                <Stack spacing={2}>
                    <Card variant="outlined">
                        <CardContent>
                            <Accordion defaultExpanded={true}>
                                <AccordionSummary><Typography variant="h5">Segments</Typography></AccordionSummary>
                                <AccordionDetails>
                                    <TableContainer component={Paper}>
                                        <Table sx={{minWidth: 700}} aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell sx={{fontWeight: 'bold', backgroundColor: '#EEEEEE'}}>Segment</TableCell>
                                                    <TableCell sx={{fontWeight: 'bold', backgroundColor: '#EEEEEE'}}>Value</TableCell>
                                                    <TableCell sx={{fontWeight: 'bold', backgroundColor: '#EEEEEE'}}>Actions</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>{renderSegments()}</TableBody>
                                        </Table>
                                    </TableContainer>
                                    <br/>
                                    Key: <input type={'text'} onChange={(evt) => setNewSegmentKey(evt.target.value)}/>&nbsp;
                                    Value: <input type={'text'} onChange={(evt) => setNewSegmentValue(evt.target.value)}/>&nbsp;
                                    <Button variant="contained" onClick={addSegment}>Add Segment</Button>
                                </AccordionDetails>
                            </Accordion>
                        </CardContent>
                    </Card>
                </Stack>
            </Container>
            <Container maxWidth="xl">
                <Stack spacing={2}>
                    <Card variant="outlined">
                        <CardContent>
                            <Accordion defaultExpanded={false}>
                                <AccordionSummary><Typography variant="h5">Inbox</Typography></AccordionSummary>
                                <AccordionDetails>
                                    {renderInbox()}
                                </AccordionDetails>
                            </Accordion>
                        </CardContent>
                    </Card>
                </Stack>
            </Container>
            <Container maxWidth="xl">
                <Stack spacing={2}>
                    <Card variant="outlined">
                        <CardContent>
                            <Accordion defaultExpanded={false}>
                                <AccordionSummary><Typography variant="h5">My Real Forest</Typography></AccordionSummary>
                                <AccordionDetails>
                                    {renderMyRealForest()}
                                </AccordionDetails>
                            </Accordion>
                        </CardContent>
                    </Card>
                </Stack>
            </Container>
            <Container maxWidth="xl">
                <Stack spacing={2}>
                    <Card variant="outlined">
                        <CardContent>
                            <Accordion defaultExpanded={false}>
                                <AccordionSummary><Typography variant="h5">Notifications</Typography></AccordionSummary>
                                <AccordionDetails>
                                    {renderNotifications()}
                                </AccordionDetails>
                            </Accordion>
                        </CardContent>
                    </Card>
                </Stack>
            </Container>
            <Container maxWidth="xl">
                <Stack spacing={2}>
                    <Card variant="outlined">
                        <CardContent>
                            <Accordion defaultExpanded={false}>
                                <AccordionSummary><Typography variant="h5">Telemetry</Typography></AccordionSummary>
                                <AccordionDetails>
                                    {renderTelemetryLog()}
                                </AccordionDetails>
                            </Accordion>
                        </CardContent>
                    </Card>
                </Stack>
            </Container>
            <Container maxWidth="xl">
                <Stack spacing={2}>
                    <Card variant="outlined">
                        <CardContent>
                            <Accordion defaultExpanded={false}>
                                <AccordionSummary><Typography variant="h5">Data</Typography></AccordionSummary>
                                <AccordionDetails>
                                    {renderPlayerTable('Resources', ['ResourceId', 'Quantity'], resources)}
                                    {renderPlayerTable('Progression', ['Level', 'XP', 'DayNightCycle'], progressions)}
                                    {renderPlayerTable('Permissions', ['Permission', 'State'], permissions)}
                                    {renderPlayerTable('Tasks', ['Chain Id', 'Current Task Id'], tasks)}
                                    {renderPlayerTable('Animals', ['Id', 'Level', 'State'], animals)}
                                    <div>
                                        <h3>Narratives</h3>
                                        {narratives}
                                    </div>
                                    {renderPlayerTable('Plantables', ['Coordinates', 'Id', 'Level', 'Cumulative Payout Total'], plantables)}
                                    {renderPlayerTable('Merge', ['Index', 'Unlocked Rows', 'Objects'], merge)}
                                    {renderPlayerTable('VIP', ['Active Until', 'Activations'], vip)}
                                </AccordionDetails>
                            </Accordion>
                        </CardContent>
                    </Card>
                </Stack>
            </Container>
            <Container maxWidth="xl">
                <Stack spacing={2}>
                    <Card variant="outlined">
                        <CardContent>
                            <Accordion defaultExpanded={true}>
                                <AccordionSummary><Typography variant="h5">Static Data</Typography></AccordionSummary>
                                <AccordionDetails>
                                    {renderStaticData()}
                                </AccordionDetails>
                            </Accordion>
                        </CardContent>
                    </Card>
                </Stack>
            </Container>
            <InboxDialog rewardTypes={rewardTypes} handleClose={() => setOpenInboxForm(false)} handleSubmit={asyncHandleSubmitGrantRevoke} open={openInboxForm} error={grantRevokeError}
                env={props.env}/>
        </div>;
    };

    const renderPlayerFound = () => {
        if (!props.player) {
            return <div key={'NoPlayer'}>
                <h1>Player Not Found</h1>
                <h3> {props.playerId}</h3>
            </div>;
        }

        return <div key={'PlayerData'}>
            <Container maxWidth="xl"><h1>Player Data</h1></Container>
            {renderPlayer()}
        </div>;
    };

    return (renderPlayerFound());
};

export default PlayerProfile;
