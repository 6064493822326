import React, {useState} from 'react';
import {Accordion, AccordionDetails, AccordionSummary, Button, Card, CardContent, Container, Grid, Stack, TextField, Typography} from '@mui/material';
import {useService} from '../../../hook/serviceLocatorHook';
import {IStaticDataSetService} from '../../../service/staticData';
import {Service} from '../../../service/serviceLocator';
import {StaticDataSetConditions} from '../staticDataSetConditions';
import {StaticDataSheetEditorProps} from '../props/staticDataSheetEditorProps';
import {DataSheetGrid} from 'react-datasheet-grid';

export const IdMappedStaticDataSheetEditor = (props: StaticDataSheetEditorProps) => {
    const [staticDataSetService] = useService<IStaticDataSetService>(Service.StaticDataSetService);
    const [grid, setGrid] = useState<any>(props.data);
    const [comment, setComment] = useState<string>();

    const onCellsChanged = (changed: any) => {
        setGrid(changed);
    };

    const updateSet = async (event) => {
        event.preventDefault();

        await staticDataSetService?.updateSet(props.context, props.definitionId, props.setId, {data: grid, comment});

        window.location.reload();
    };

    return (
        <div>
            <Container maxWidth="xl">
                <Stack spacing={2}>
                    <Card variant="outlined">
                        <CardContent>
                            <Accordion defaultExpanded={true}>
                                <AccordionSummary><Typography variant="h5">Conditions</Typography></AccordionSummary>
                                <AccordionDetails>
                                    <StaticDataSetConditions
                                        context={props.context}
                                        definitionId={props.definitionId}
                                        setId={props.setId}
                                        conditions={props.conditions}
                                    />
                                </AccordionDetails>
                            </Accordion>
                        </CardContent>
                    </Card>
                </Stack>
            </Container>

            <h3>Save</h3>

            <Grid container spacing={2}>
                <Grid item xs={2}>
                    <Typography variant="h6">Comment: </Typography>
                </Grid>
                <Grid item xs={10}>
                    <TextField rows={1} fullWidth={true} defaultValue={comment} onChange={evt => setComment(evt.target.value)}/>
                </Grid>
            </Grid>

            <Button onClick={updateSet} variant="contained">Save Data</Button>

            <div className="grid-wrapper">
                <DataSheetGrid
                    value={grid}
                    onChange={onCellsChanged}
                    columns={props.columns}
                    rowHeight={40}
                    headerRowHeight={45}
                    height={grid.length * 60}
                />
            </div>

        </div>);
};
